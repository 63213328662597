import React, { useEffect } from "react";

function Academics() {
  useEffect(() => {
    document.title = "Academics";
  });
  return (
    <div className="content">
      <div className="w-container">
        <div className="page-head">
          <div className="page-title">
            <h1 className="h1">Academics</h1>
          </div>
        <div class="sub-hrule"></div>
        </div>
        <div className="people-section">
          <div id="paanav" className="from-navbar-ref"></div>
          <div id="paa" className="page-nav-ref"></div>
          <div className="sub-head">
            <h3 className="h3">Peer Academic Advisors (PAAs)</h3>
            <div className="sub-hrule"></div>
            <p className="paragraph-center sub">
              Peer Academic Advisors (PAAs) serve as liaisons between students,
              faculty, and the Office of Academic Advising. Throughout the year,
              they coordinate events to inform students about scheduling,
              research opportunities, and other facets of academic life.
            </p>
          </div>
          <div class="rha-content">
            <div class="rha-card-wrap">
              <h4 class="h4">Aoife Shannon</h4>
              <a class="position" href="mailto: as375@rice.edu">
                <u>as375@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Barakat Ibrahim</h4>
              <a class="position" href="mailto: bai1@rice.edu">
                <u>bai1@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Chandler Bannis</h4>
              <a class="position" href="mailto: cmb26@rice.edu">
                <u>cmb26@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Charis Xiong</h4>
              <a class="position" href="mailto: cx25@rice.edu">
                <u>cx25@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Elaine Liu</h4>
              <a class="position" href="mailto: yl204@rice.edu">
                <u>yl204@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Erin Chen</h4>
              <a class="position" href="mailto: ec82@rice.edu">
                <u>ec82@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Esha Shenoy</h4>
              <a class="position" href="mailto: ers12@rice.edu">
                <u>ers12@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Jane Yoo</h4>
              <a class="position" href="mailto: jy121@rice.edu">
                <u>jy121@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Lana Kim</h4>
              <a class="position" href="mailto: lek4@rice.edu">
                <u>lek4@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Layal Haider</h4>
              <a class="position" href="mailto: lh55@rice.edu">
                <u>lh55@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Lucy Bozinov</h4>
              <a class="position" href="mailto: leb13@rice.edu">
                <u>leb13@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Mariam Khan</h4>
              <a class="position" href="mailto: mk155@rice.edu">
                <u>mk155@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Michael Bolanos</h4>
              <a class="position" href="mailto: bb72@rice.edu">
                <u>bb72@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Sam Lim</h4>
              <a class="position" href="mailto: sll13@rice.edu">
                <u>sll13@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Zach Yu</h4>
              <a class="position" href="mailto: zcy1@rice.edu">
                <u>zcy1@rice.edu</u>
              </a>
            </div>
          </div>
        </div>
        <div className="people-section">
          <div id="paanav" className="from-navbar-ref"></div>
          <div id="paa" className="page-nav-ref"></div>
          <div className="sub-head">
            <h3 className="h3">Peer Career Advisors (PCAs)</h3>
            <div className="sub-hrule"></div>
            <p className="paragraph-center sub">
              Peer Career Advisors (PCAs) are student volunteers that serve as 
              ambassadors to undergraduates and help play a valuable role in supporting 
              the CCD. PCAs are specifically trained to assist with resume and cover 
              letter reviews, how to use 12twenty, internship and job research basics, 
              and understanding CCD services. 
            </p>
          </div>
          <div class="rha-content">
            <div class="rha-card-wrap">
              <h4 class="h4">Aoife Shannon</h4>
              <a class="position" href="mailto: as375@rice.edu">
                <u>as375@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Nick Hu</h4>
              <a class="position" href="mailto: nkh5@rice.edu">
                <u>nkh5@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Skyler Zinker</h4>
              <a class="position" href="mailto: scz2@rice.edu">
                <u>scz2@rice.edu</u>
              </a>
            </div>
            <div class="rha-card-wrap">
              <h4 class="h4">Tiana Wang</h4>
              <a class="position" href="mailto: tzw2@rice.edu">
                <u>tzw2@rice.edu</u>
              </a>
            </div>
          </div>
        </div>
        <div className="people-section">
          <div id="mentornav" className="from-navbar-ref"></div>
          <div id="mentor" className="page-nav-ref"></div>
          <div className="sub-head">
            <h3 className="h3">Academic Mentors</h3>
            <div className="sub-hrule"></div>
            <p className="paragraph-center sub">
              Academic Mentors are here to help you in your classes through review
              sessions or individual tutoring sessions. They also invite
              speakers and plan other events focused on the academic life of the
              residential college.
            </p>
            <a
              href="https://linktr.ee/ricefellowsmentors"
              className="popup-button blm-button"
            >
              Request Academic Assistance
            </a>
          </div>
          <div className="mentors-content">
            <h5 id="paa-content-name" class="h5">
              Name
            </h5>
            <h5 id="paa-content-major-minor-track" class="h5">
              Subjects/Courses
            </h5>
            <h5 id="paa-content-email" class="h5">
              NetID
            </h5>
            <h5 id="paa-content-year" class="h5">
              Year
            </h5>
            <p id="paa-content-element" class="paa-mentors-data">
              Amy Zuo
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              COMP 140, BUSI 305
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              az28
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2025
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Annie McKenzie
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              HIST 291, ANTH 205, POLI 212, SPAN 322
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              anm13
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2025
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Ashley Song
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              STAT 405/410, MATH 220/232/290/354, COMP 140
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              as385
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2027
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Elaine Liu
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              COMP 140/182, STAT 405/410/418/419, MATH 355
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              yl204
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2025
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Langston Ford
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              SOC 302, POLI 395/338, FREN 141/142
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              lmf9
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2025
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Leo Zhang
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              CHEM 211/212, PHYS 125/126, BIOS 301
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              lyz2
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2025
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Lindsay Wright
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              PHYS 101, MECH 200/315/371/310/481
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              lw76
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2025
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Maleah Brady
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              COMP 140, MATH 212, 355, ANTH 205
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              mmb20
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2026
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Milton Nguyen
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              CHEM 121, MATH 102, BIOS 201
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              mtn10
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2026
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Riya Misra
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              ENG 300, ANTH 399, SOCI 231, SOSC 302
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              rm105
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2025
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Tom Punnen
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              BIOS 300
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              tgp3
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2025
            </p>
          </div>
        </div>
        <div className="people-section">
          <div id="paanav" className="from-navbar-ref"></div>
          <div id="paa" className="page-nav-ref"></div>
          <div className="sub-head">
            <h3 className="h3">Divisional Advisors</h3>
            <div className="sub-hrule"></div>
            <p className="paragraph-center sub">
            Divisional Advisors are faculty associates of Wiess College 
            that are selected by college magisters and the OAA to provide academic 
            advising to Wiessmen prior to declaring their major. These individuals are 
            excellent resources to answer questions about course choices, short and long 
            term planning, graduation requirements, and other educational opportunities. 
            </p>
          </div>
          <div className="da-content">
            <h5 id="paa-content-name" class="h5">
              Name
            </h5>
            <h5 id="paa-content-major-minor-track" class="h5">
              School
            </h5>
            <h5 id="paa-content-email" class="h5">
              Department
            </h5>
            <h5 id="paa-content-year" class="h5">
              Email
            </h5>
            <h5 id="paa-content-test" class="h5">
              Office
            </h5>
            <p id="paa-content-element" class="paa-mentors-data">
              Jeff Russel
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Business
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Business
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              jeff.russell@rice.edu
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              223 McNair Hall
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Rebecca Schreib
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Engineering
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Computer Science
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              rebecca.j.smith@rice.edu
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              2064 Duncan Hall
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Renata Remos
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Engineering
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Bioengineering
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              renata.ramos@rice.edu
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              1011 Duncan Hall
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Helade Scutti Santos
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Humanities
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Center for Language and Intercultural Communication
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              helade.santos@rice.edu
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              234 Rayzor Hall
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Betul Orcan-Ekmekci
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Natural Sciences
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Math
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              orcan@rice.edu
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              440 Herman Brown
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Daniel Wagner
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Natural Sciences
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Biosciences
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              dswagner@rice.edu
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              351 Anderson Biological Lab
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Tilsa Ore Monago
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Social Sciences
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Economics
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              tilsa@rice.edu
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              N/A
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Carisaa Zimmerman
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Social Sciences
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              Psychological Sciences
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              carissa.a.zimmerman@rice.edu
            </p>
            <p id="paa-content-element" class="paa-mentors-data">
              472 Sewall Hall
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Academics;
