function Footer() {
  return (
    <div class="footer">
      <div class="w-container">
        <div class="flex-vert-center">
          <img
            src="/images/general/crest-color.png"
            width="42"
            height="49"
            srcset="/images/general/crest-color-p-500.png 500w, images/general/crest-color-p-800.png 800w, images/general/crest-color.png 1216w"
            sizes="42px"
            alt=""
          />
          <div class="hrule"></div>
        </div>
        <div class="footer-content">
          <div class="flex-vert-left">
            <h4 class="h3" style={{ marginBottom: "10px" }}>Address</h4>
            <>
              <p>
                Wiess College
                <br />
                6340 Main St.
                <br />
                Houston, TX 77005
              </p>
            </>
          </div>
          <div class="flex-vert-left">
            <h4 class="h3" style={{ marginBottom: "10px" }}>Contact</h4>
            <>
              (713) 348-5743
              <br />
              wiess@rice.edu
            </>
          </div>
          <div class="flex-vert-left">
            <h4 class="h3" style={{ marginBottom: "10px" }}>Common Forms</h4>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSdA-cPoab2-_cpES7NmltroDcQkY-fBpPoTH1exmP-LCf9YOg/viewform?c=0&amp;w=1" style={{textDecoration: "underline"}}>
              Reserve Room
            </a>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSe2x2sCyBdw44_FB8IaTtZz19jOEfNp9WL0JpR_E7B00csWSQ/viewform" style={{textDecoration: "underline"}}>
              Submit Agenda Items
            </a>
          </div>
          <div class="flex-vert-left">
            <h4 class="h3" style={{ marginBottom: "10px" }}>Navigation</h4>
            <a href="index" style={{textDecoration: "underline"}}>Home</a>
            <a href="newstudents" style={{textDecoration: "underline"}}>New Students</a>
            <a href="forms" aria-current="page" class="w--current" style={{textDecoration: "underline"}}>All Forms</a>
          </div>
        </div>
        <div>
          <p class="footer-credit">
            Made with lots and lots of tea and jazz by Andy Cheng
            <br />
            Currently maintained by Maleah Brady
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
