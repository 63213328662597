import { useEffect } from "react";
import React from "react";
// import Countdown from "./Countdown";

function NewStudents() {
  useEffect(() => {
    document.title = "New Students";
  });
  return (
    <div class="content">
      <div class="w-container">
        <div class="page-head">
          <div class="page-title">
            <h1 class="h1">New Students</h1>
          </div>
          <div class="flex-vert">
            <div class="sub-hrule"></div>
            <p class="paragraph-center sub">
              This page contains everything you need to know about <span style={{color: "goldenrod", fontWeight: 'bold'}}>Wiess O-Week</span>. 
              Read about what to expect for move-in below, and find everything else 
              in the O-Week book linked at the bottom of this page.
            </p>
          </div>
        </div>
        <div class="sub-head">
          <h2 class="h3">Countdown to O-Week!</h2>
          <div class="sub-hrule"></div>
          {
          /* Comment out Countdown duing school year.
          <Countdown date="2024-08-18 00:00" /> 
          */}
          <p class="paragraph-center sub">O-Week is Here!</p>
        </div>
        <div class="sub-head" style={{ marginTop: '70px' }}>
          <h2 class="h3">O-Week Book (2024)</h2>
          <div class="sub-hrule"></div>
          <a
            style={{ marginTop: "2%", marginBottom: "6%" }}
            data-w-id="7807c01e-0929-1697-07ed-9b89da4c4bcf"
            href="https://drive.google.com/file/d/1_p48SgpZEhW5-OwnYSvapEuSdWyS2ROY/view"
            class="w-inline-block"
          >
            <img
              src="/images/new-students/oweekbook.png"
              srcset="/images/new-students/oweekbook-p-500.png 500w, images/new-students/oweekbook-p-800.png 800w, images/new-students/oweekbook-p-1080.png 1080w, images/new-students/oweekbook.png 1600w"
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 94vw, (max-width: 991px) 728px, 940px"
              data-w-id="5a43e2e8-260e-ceb9-9db0-111b860959f1"
              alt="Oweek book"
            />
          </a>
          <img
            src="/images/new-students/2024Oweek.jpg"
            alt="O-Week 2024"
            style={{ display: 'block', width: '100%', height: 'auto', marginBottom: '40px' }}
          />
        </div>
        <div class="sub-head">
          <h2 class="h3">Mission Statement</h2>
          <div class="sub-hrule"></div>
        </div>
        <div class="sub-head" style={{ marginBottom: '30px' }}>
          <h3 class="h3">Why “Team Family Wiess”?</h3>
        </div>
        <div class="flex-vert" style={{ marginBottom: '40px' }}>
          <p class="paragraph-center sub">
          Wiess is not just a residence; it’s a community. At Wiess, we honor our history while 
          reimagining the spirit of our traditions, creating a space where every member can 
          flourish personally, academically, and socially. O-Week 2024 will be based on the core 
          values of empathy, integrity, respect, and inclusivity. With the guidance of Fellow, 
          Coord, and Core Teams, New Students will be empowered with the confidence to define 
          and achieve their own visions of success. Wiess O-Week aims to not just acknowledge, but 
          celebrate our diverse experiences and backgrounds. Students will be provided with the 
          tools to build lasting connections with their peers and mentors, which in turn will allow
          them to begin a journey of self discovery that will carry them beyond these next four years.
          Regardless of where students find themselves at Rice, they will always have a home at Team 
          Family Wiess.
          <br /> <br />
          TFW Forever, <br /> Barakat, Hannah, Peter
          </p>
        </div>
        {/* This commented out section includes outdated information of the o-week groups */}
        {/*
        <div class="sub-head">
          <h3 class="h2">Meet the O-Week Team</h3>
          <div class="sub-hrule"></div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Head Fellows</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/raw.jpg"
              alt="Wiess Oweek Coords"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Ryan Lu</h4>
              </div>
              <p class="position">Philosophy</p>
              <p class="position">Houston, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Addison Saley</h4>
              </div>
              <p class="position">Biosciences</p>
              <p class="position">Seabrook, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Michael Wang</h4>
              </div>
              <p class="position">Health Sciences</p>
              <p class="position">Chicago, IL</p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div class="sub-head">
            <h3 class="h3">President and Chief Justice</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/prescj.jpg"
              alt="Wiess CJ and Wiess President"
            />
          </div>

          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">CJ Renzo Espinoza</h4>
              </div>
              <p class="position">Computer Science</p>
              <p class="position">Ft.Lauderdale, FL</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">President Blaine Samson</h4>
              </div>
              <p class="position">Philosophy and Social Policy Analysis</p>
              <p class="position">Irvine, CA</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Birds of Parawiess</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/birds of parawiess-5.jpg"
              alt="Birds of Parawiess Oweek Group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Christina Boufarah</h4>
              </div>
              <p class="position">History</p>
              <p class="position">The Woodlands, TX </p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Meghan Lim</h4>
              </div>
              <p class="position">Cognitive Sciences</p>
              <p class="position">Los Angeles, CA</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Connor Nguyen</h4>
              </div>
              <p class="position">Neuroscience and Asian Studies</p>
              <p class="position">Houston, TX</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Shrimp Fried Wiess</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/ShrimpFriedWiess.jpg"
              alt="Shrimp Fried Wiess oweek group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Sarah Birenbaum</h4>
              </div>
              <p class="position">Cognitive Sciences</p>
              <p class="position">Houston, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Milton Nguyen</h4>
              </div>
              <p class="position">Health Sciences</p>
              <p class="position">Sacramento, CA</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Chandler Bannis</h4>
              </div>
              <p class="position">Biosciences</p>
              <p class="position">Houston, TX</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Piggy Azalea</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/piggy-azalea.jpg"
              alt="Piggy Azalea Oweek Group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Pia Arana</h4>
              </div>
              <p class="position">
                Sports Medicine & Exercise Physiology and Psychology
              </p>
              <p class="position">Houston, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Alex Zalles</h4>
              </div>
              <p class="position">Operations Research and Creative Writing</p>
              <p class="position">Austin, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Amber Liu</h4>
              </div>
              <p class="position">Economics and History</p>
              <p class="position">Shenzhen, China</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Notorious PIG</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/notoriouspig.jpg"
              alt="Notorious PIG oweek group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Max Slotnik</h4>
              </div>
              <p class="position">Biosciences</p>
              <p class="position">Evergreen, CO</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Brendan Hilbok</h4>
              </div>
              <p class="position">Mechanical Engineering</p>
              <p class="position">Ellicott City, MD</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Hannah Abell</h4>
              </div>
              <p class="position">English</p>
              <p class="position">Newport Beach, CA</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Shay Olaifa</h4>
              </div>
              <p class="position">Political Science</p>
              <p class="position">Houston, TX</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Spwiess Girls</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/Spwiess Girls-2-2.jpg"
              alt="Spwiess Girls oweek group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Jerome Cerio</h4>
              </div>
              <p class="position">
                Computer Science and Visual & Dramatic Arts
              </p>
              <p class="position">Sugar Land, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Esha Shenoy</h4>
              </div>
              <p class="position">Health Sciences</p>
              <p class="position">Westchester, NY</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Emily Han</h4>
              </div>
              <p class="position">Biosciences</p>
              <p class="position">Leesburg, FL</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Warpigs Inc.</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/warpigsinc.JPG"
              alt="Warpigs inc oweek group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Naomi Sahle</h4>
              </div>
              <p class="position">
                Health Sciences and Sports Medicine & Exercise Physiology
              </p>
              <p class="position">Asmara, Eritrea</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Hugo Gerbich Pais</h4>
              </div>
              <p class="position">European Studies and English</p>
              <p class="position">Auckland, New Zealand</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Kaleb Cox</h4>
              </div>
              <p class="position">Mechanical Engineering</p>
              <p class="position">Atascocita, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Zoe Katz</h4>
              </div>
              <p class="position">Linguistics and Computer Science</p>
              <p class="position">Dallas, TX</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Wiess for What</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/Wiess for What-1-2.jpg"
              alt="Wiess for What oweek group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Aishani Gargapati</h4>
              </div>
              <p class="position">Biosciences</p>
              <p class="position">Troy, MI</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Vivian Phillips</h4>
              </div>
              <p class="position">English</p>
              <p class="position">San Antonio, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Newton Huynh</h4>
              </div>
              <p class="position">Computer Science</p>
              <p class="position">Houston, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Kritin Shanmugam</h4>
              </div>
              <p class="position">Operations Research</p>
              <p class="position">Hinsdale, IL</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Wiess Cube</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/WiessCubeEdited-4.jpg"
              alt="Wiess Cube oweek group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Benji Wilton</h4>
              </div>
              <p class="position">History and Sports Management</p>
              <p class="position">St. Lous, MO</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Samantha Lim</h4>
              </div>
              <p class="position">Computer Science</p>
              <p class="position">Bilmont, MA</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Logan Simon</h4>
              </div>
              <p class="position">Mathematics and Computation Physics</p>
              <p class="position">Arlinton, TX</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Wiess Age</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/wiessage.jpg"
              alt="Wiess Age oweek group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Lindsey Pompilus</h4>
              </div>
              <p class="position">Neuroscience and Computer Science</p>
              <p class="position">Easton, MA</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Tom Punnen</h4>
              </div>
              <p class="position">Neuroscience</p>
              <p class="position">Frisco, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Christina Chen</h4>
              </div>
              <p class="position">Economics</p>
              <p class="position">Tuscon, AZ</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Wiess on my Wrist</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/Wiess on my wrist - 1.jpg"
              alt="Wiess on My Wrist oweek group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Perry Gilberd</h4>
              </div>
              <p class="position">Biosciences</p>
              <p class="position">Miami, FL</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Sam Mulford</h4>
              </div>
              <p class="position">Psychology</p>
              <p class="position">Washington D.C.</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Leo Zhang</h4>
              </div>
              <p class="position">Biosciences</p>
              <p class="position">Chester Springs, PA</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Where the Wild Pigs are</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/Where the Wild Pigs Are - 5.JPG"
              alt="Where the Wild Pigs Are oweek group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Peter Stern</h4>
              </div>
              <p class="position">Operations Research</p>
              <p class="position">Chicago, IL</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Jaylin Vinson</h4>
              </div>
              <p class="position">Music Composition</p>
              <p class="position">Oklahoma City, OK</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Ava Fradlin</h4>
              </div>
              <p class="position">Sports Medicine & Exercise Physiology</p>
              <p class="position">Potomac, MD</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Wiess Spice</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/wiess-spice.JPG"
              alt="Wiess Spice oweek group"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Soren Freund</h4>
              </div>
              <p class="position">English</p>
              <p class="position">Elizabeth, CO</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Barakat Ibrahim</h4>
              </div>
              <p class="position">Biosciences</p>
              <p class="position">West Point, NY</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Daniel Gu</h4>
              </div>
              <p class="position">Computer Science</p>
              <p class="position">Portland, OR</p>
            </div>
          </div>
        </div>
        <div class="people-section">
          <div class="sub-head">
            <h3 class="h3">Peer Academic Advisors (PAAs)</h3>
            <br />
            <img
              style={{ height: "400px", objectFit: "cover" }}
              src="/images/oweek2022/PAA-1-2.jpg"
              alt="Wiess oweek PAAs"
            />
          </div>
          <div class="people-grid">
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Edwin Low</h4>
              </div>
              <p class="position">Electrical Engineering</p>
              <p class="position">Taipei, Taiwan</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Drew Moore</h4>
              </div>
              <p class="position">Biosciences and Linguistics</p>
              <p class="position">Arlington, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Michael Bolanos</h4>
              </div>
              <p class="position">Biosciences and Visual Arts</p>
              <p class="position">Houston, TX</p>
            </div>
            <div id="oweek-fellow" class="person-card">
              <div class="div-block-15">
                <h4 class="h4">Swetha Kesavan</h4>
              </div>
              <p class="position">Health Sciences and Anthropology</p>
              <p class="position">Odessa, TX</p>
            </div>
          </div>
        </div>
        <div class="ns-section">
          <div class="sub-head">
            <h3 class="h3">Wiess Spaces</h3>
            <div class="sub-hrule"></div>
          </div>
          <div class="gallery">
            <img src="/images/new-students/commons.jpg" alt="Wiess Commons" />
            <img src="/images/new-students/acabowl.jpg" alt="Wiess acabowl" />
            <img src="/images/new-students/hall.jpg" alt="Wiess hall" />
            <img src="/images/new-students/alcove.jpg" alt="Wiess alcove" />
            <img src="/images/new-students/beds.jpg" alt="Wiess beds" />
            <img src="/images/new-students/onedesk.jpg" alt="Wiess desk" />
            <img src="/images/new-students/desks.jpg" alt="Wiess desks" />
            <img src="/images/new-students/bathroom.jpg" alt="Wiess bathroom" />
          </div>
        </div>
        */}
        <div class="ns-section">
          <div class="sub-head">
            <h2 class="h3">Additional Resources</h2>
            <div class="sub-hrule"></div>
          </div>
          <div class="flex-vert">
            <a
              href="https://www.instagram.com/wiessoweek2024/"
              class="form-link w-inline-block"
            >
              <h3 class="h3">Instagram</h3>
            </a>
            <a
              href="https://www.youtube.com/watch?v=-Kxycbg8uoU"
              class="form-link w-inline-block"
            >
              <h3 class="h3">Room Tour Video</h3>
            </a>
            <a
              href="https://www.youtube.com/watch?v=niJLyuonnNQ"
              class="form-link w-inline-block"
            >
              <h3 class="h3">Wiess Introduction Video</h3>
            </a>
            <a
              href="https://success.rice.edu/first-year-programs/o-week"
              class="form-link w-inline-block"
            >
              <h3 class="h3">Rice First Year Programs Website</h3>
            </a>
            <a
              href="https://success.rice.edu/first-year-programs/o-week/new-student-checklist"
              class="form-link w-inline-block"
            >
              <h3 class="h3">New Student Checklist</h3>
            </a>
            <a
              href="https://success.rice.edu/first-year-programs/o-week/o-week-faqs"
              class="form-link w-inline-block"
            >
              <h3 class="h3">O-Week FAQ</h3>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewStudents;
