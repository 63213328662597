export const routes = [
    {
      name: "Government",

      subRoutes: [
        {
          name: "Core Team",
          link: "/government/coreteam",
        },
        {
          name: "Cabinet",
          link: "/government/cabinet",
        },
        {
          name: "Court",
          link: "/government/court",
        },
        {
            name: "Representatives",
            link: "/government/representatives",
          },
          {
            name: "View Budget",
            link: "https://docs.google.com/spreadsheets/d/10bPS_hfMFgPNqtVTNi5MADesJCjsC5ISHOo67IgopD0/edit?gid=1359877527#gid=1359877527",
          },
          {
            name: "View Constitution",
            link: "https://docs.google.com/document/d/1qbvMy553nreKAKAUgoLXazVCsHW0O-9FT_FoGfq4PbM/edit",
          },
          {
            name: "View Bylaws",
            link: "https://drive.google.com/file/d/1RdnCgfVHw0T7OFxbiz_fPOuPSwKLEvWE/view",
          },
      ],
    },
    {
      name: "Resources",
  
      subRoutes: [
        {
          name: "Academics",
          link: "/resources/academics",
        },
        {
          name: "Associates",
          link: "/resources/associates",
        },
        {
          name: "Health & Wellbeing",
          link: "/resources/health",
        },
        {
            name: "Diversity & Inclusion",
            link: "/resources/diversity",
          },
          {
            name: "Designs",
            link: "https://drive.google.com/drive/folders/0B52XkHoVvpYhcXhDbGoxb0tjbUE?usp=sharing",
          },
          {
            name: "Rice-Wide",
            link: "https://docs.google.com/spreadsheets/d/14kvozYUheCTkH-QqxSWfO8OMQrILdgr7cW3wF2ePQJs/edit?gid=1352693617#gid=1352693617",
          },
      ],
    },
    {
      name: "New Students",
      link: "/newstudents",
    },
    {
        name: "Income Accessibility",
    
        subRoutes: [
          {
            name: "Guidelines",
            link: "https://drive.google.com/file/d/0BwCbCY3Ekm3dRkpsT3had1ZPdjhTcXV3YUtOTG9KSzlQcU44/view",
          },
          {
            name: "Request Form",
            link: "https://docs.google.com/forms/d/e/1FAIpQLSc9FTdt14fM9h35KYJmRewswqbi536S7WnxPDVDumUNPDA3Qg/viewform",
          },
        ],
    },
    {
        name: "Forms",
    
        subRoutes: [
          {
            name: "Reserve Room",
            link: "https://docs.google.com/forms/d/e/1FAIpQLSdA-cPoab2-_cpES7NmltroDcQkY-fBpPoTH1exmP-LCf9YOg/viewform?c=0&w=1",
          },
          {
            name: "Submit Agenda Item",
            link: "https://docs.google.com/forms/d/e/1FAIpQLSe2x2sCyBdw44_FB8IaTtZz19jOEfNp9WL0JpR_E7B00csWSQ/viewform",
          },
          {
            name: "Submit Snaps",
            link: "https://docs.google.com/forms/d/e/1FAIpQLScm2fPYeETJyX9fHRDVS8bDCmqyO82nWk6QT1Pt0-HUOWIPuA/viewform?c=0&w=1",
          },
          {
            name: "Budget Request",
            link: "https://docs.google.com/forms/d/e/1FAIpQLSesFdQMh3OxQFE6tar7RLu_0YLCJhPVuYKyy9ucYGFfqGJmxw/viewform",
          },
          {
            name: "Turn in Receipts",
            link: "https://docs.google.com/forms/d/e/1FAIpQLSeTKyK872Nqf5zPzxBzQZBpI2jRy0rcvudp1rVIruY2P2-xqw/viewform",
          },
          {
            name: "Court Infractions Form",
            link: "https://docs.google.com/forms/d/e/1FAIpQLSes8Z8fq0mSqAZdWntODAKnE58MJYnmwbgk8aBEhamRHf5Adg/viewform",
          },
          {
            name: "All Forms",
            link: "/forms",
          },
        ],
    },
  ];