import { useEffect } from "react";

function Forms() {
  useEffect(() => {
    document.title = "Forms";
  }, []);

  return (
    <div className="content">
      <div className="w-container">
        <div className="page-head">
          <div className="page-title">
            <h1 className="h1">All Forms</h1>
          </div>
          <div class="sub-hrule"></div>
          <p className="paragraph-center sub">
            Collection of all forms used throughout the college. <br />
            (Hint: ctrl-F to quickly find the form you&#x27;re looking for)
          </p>
        </div>
        <div className="forms-grid">
          <a
            id="form"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdA-cPoab2-_cpES7NmltroDcQkY-fBpPoTH1exmP-LCf9YOg/viewform?c=0&amp;w=1"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Room Reservation</h3>
          </a>
          <a
            id="form"
            href="https://docs.google.com/forms/d/e/1FAIpQLSe2x2sCyBdw44_FB8IaTtZz19jOEfNp9WL0JpR_E7B00csWSQ/viewform"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Cabinet Agenda Item</h3>
          </a>
          <a
            id="form"
            href="https://docs.google.com/forms/d/e/1FAIpQLScm2fPYeETJyX9fHRDVS8bDCmqyO82nWk6QT1Pt0-HUOWIPuA/viewform?c=0&amp;w=1"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Submit Snaps</h3>
          </a>
          <a
            id="form"
            href="https://forms.gle/yhGrt4TKnDFYoR7M8"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Budget Request</h3>
          </a>
          <a
            id="form"
            href="https://forms.gle/xUGJuj1VXfppds918"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Turn in Receipts</h3>
          </a>
          <a
            id="form"
            href="https://forms.gle/28NxYdpZBEkowoBW7"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Court Infractions Form</h3>
          </a>
          <a
            id="form"
            href="https://forms.gle/463y3WvLcn6oGgSh6"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Income Accessibility</h3>
          </a>
          <a
            id="form"
            href="https://forms.gle/CTXvDB7X2YoxGmyd7"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Freshman Hours</h3>
          </a>
          <a
            id="form"
            href="https://forms.gle/9Z3ok8o4rPa58dLu9"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Submit Announcement Links</h3>
          </a>
          <a
            id="form"
            href="https://forms.gle/YCM6Z56DqoFAASLg7"
            className="form-link w-inline-block"
          >
            <h3 className="h3">President Feedback Form</h3>
          </a>
          <a
            id="form"
            href="https://docs.google.com/forms/d/e/1FAIpQLScGTfDlimg_zXhCTqWfqi74Lu3wi-1f2IRtihRnbfgENFegpg/viewform?gxids=7757"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Academic Assistance</h3>
          </a>
          <a
            id="form"
            href="https://forms.gle/wu9TzR1NiY3UJynN8"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Capital Improvements</h3>
          </a>
          <a
            id="form"
            href="https://docs.google.com/forms/d/e/1FAIpQLScUACDyYKKOqgfQp2e9ayT0mNMHAL2qU6QULjaPeG01s_m-VQ/viewform?c=0&amp;w=1"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Board Game Checkout</h3>
          </a>
          <a
            id="form"
            href="https://docs.google.com/forms/d/e/1FAIpQLSc96__LXV2BuEOppiKtXDUbd7res450J6Yu-epHhSQJZpbAgg/viewform"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Greenware Checkout</h3>
          </a>
          <a
            id="form"
            href="https://docs.google.com/forms/d/e/1FAIpQLSf8Z_Jk59RJGOhBtAOfQPJafI9RgRhVoINdsYiOTSXupuU5LQ/viewform"
            className="form-link w-inline-block"
          >
            <h3 className="h3">T-Shirt Screen</h3>
          </a>
          <a
            id="form"
            href="https://docs.google.com/forms/d/e/1FAIpQLSe33X9glnLufFp_IxinrTUNqatKe4nzA-ohCetePkxRqI8Otg/viewform"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Pig Proclaimer</h3>
          </a>
          <a
            id="form"
            href="https://forms.gle/76qrwD5e9qmANWDD8"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Weekly Announcement</h3>
          </a>
          <a
            id="form"
            href="https://docs.google.com/forms/d/e/1FAIpQLSekerl0IdHAjO40KzbnaACRYnCyhuXBu78A8QNqsSNzoKiGSg/viewform"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Check Out A Book</h3>
          </a>
          <a
            id="form"
            href="https://docs.google.com/forms/d/e/1FAIpQLSdrv7Nk26Z1lL3HYbYwKD0_4EdPj2OpL8Hc2C1yFxSPOb_6nQ/viewform"
            className="form-link w-inline-block"
          >
            <h3 className="h3">Recommend A Book </h3>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Forms;
