import { useEffect } from "react";

const openPopup = (id) => {
  document.getElementById(id).style.display = "block";
};

/*
 * Closes the specified popup.
 */
const closePopup = (id) => {
  document.getElementById(id).style.display = "none";
};

function Associates() {
  useEffect(() => {
    document.title = "Associates";
  });
  return (
    <div class="content">
      <div class="w-container">
        <div class="page-head">
          <div class="page-title">
            <h1 class="h1">Associates</h1>
          </div>
          <div class="flex-vert" style={{ marginTop: "-10px" }}>
            <div class="sub-hrule"></div>
            <p class="paragraph-center sub">
              Associates are valued members of the Rice faculty, staff, and
              community who offer resources and support for Wiessmen. This looks
              different for every associate, ranging from experienced academic
              and career advice within their respective fields, fun study breaks
              in Wiess common spaces, and help welcoming the New Students during
              O-Week. The associates showcased below does not encompass our
              entire associates community, but offers a glimpse into some of
              what Wiess associates have to offer across disciplines and
              interests. If you are a Wiessman who is interested in speaking to
              one of the associates listed below, feel free to use their listed
              emails to reach out directly, or reach out to the Wiess EVP (see
              Cabinet members webpage) for an extended list of associates. If
              you are interested in becoming a Wiess associate, please see the{" "}
              <a
                href="https://drive.google.com/file/d/16E9SMGhFJE8KKDkpXsFS8lKBAxtFVDhd/view"
                class="link"
              >
                Wiess Associates Guide
              </a>
              .
            </p>
          </div>
        </div>
        <div class="people-section">
          <div class="people-grid">
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("arbizu-popup")}
              >
                <img
                  src="/images/associates/arbizu.png"
                  height="200"
                  alt="Associate Arbizu"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("arbizu-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Viqui Arbizu-Sabater &amp; L. Alfonso DuLuc
                </h4>
              </div>
              <p class="position">
                ARRUF International Ambassador in Spain; Bespoke Tour Operators
                for Andalusia, Spain
              </p>
              <a href="mailto: varbizu@rice.edu" class="link">
                varbizu@rice.edu
              </a>
              <div id="arbizu-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseArbizu"
                    class="close-button"
                    onClick={() => closePopup("arbizu-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      Viqui Arbizu-Sabater &amp; L. Alfonso DuLuc
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Dr. Arbizu-Sabater (Viqui) has been part of the Rice
                      family since 1997 when she was offered an M.A. scholarship
                      to come to Rice from her native Seville, Spain. She taught
                      Spanish at various levels at the Rice Center for the Study
                      of Languages where she became Senior Lecturer until she
                      retired in 2016. While at Rice, she founded the weekly
                      Spanish Luncheon Table at Wiess College and led it until
                      she retired. Her husband, Alfonso Duluc, and she are proud
                      to be Wiess Associates where they worked with the students
                      and hosted freshmen for about ten years. Viqui is now part
                      of ARRUF (Association of Retired Rice University Faculty)
                      where she continues to participate in Rice activities and
                      online events. Alfonso worked as a bilingual and special
                      education teacher both in the US and abroad. He has also
                      participated as a guest actor in several theater
                      performances in Houston as well as in Wiess College
                      activities. In the summer of 2019, they moved to Spain
                      where they opened a theme-designed tour agency, Yugen &
                      Duende-Vive Andalucia, focused on unique travel
                      experiences of southern Spain. Viqui is also ARRUF’s
                      International Ambassador where she works as liaison
                      between Rice University’s retired faculty and academic
                      institutions in Spain. Viqui and Alfonso are happy to help
                      our Wiess Family plan trips to Spain, and if you just want
                      to visit us in Seville, you are more than welcome.
                      ¡Bienvenidos!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("arnold-popup")}
              >
                <img
                  src="/images/associates/arnold.png"
                  height="200"
                  alt="Associate Arnold"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("arnold-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Frank &amp; Michelle Arnold
                </h4>
              </div>
              <p class="position">
                VP of Administration, Houston Dynamo; Professional Home
                Organizer
              </p>
              <a href="mailto: michelleannearnold@yahoo.com" class="link">
                michelleannearnold@yahoo.com
              </a>
              <div id="arnold-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseArnold"
                    class="close-button"
                    onClick={() => closePopup("arnold-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      Frank &amp; Michelle Arnold
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Frank and Michelle Arnold met at Wiess during sophomore
                      year and have been inseparable since. Michelle majored in
                      Biological Sciences and was active in the college as a
                      Social VP and Diversity Coordinator for O-Week. She
                      currently owns her own professional organizing business in
                      Houston, Good Clean Function, and is an active crossfitter
                      and gardener. Frank majored in Sports Management and
                      enjoyed playing lacrosse, being the External VP, and a
                      fellow for O-Week. He has served in multiple roles for the
                      Houston Dynamo since 2007 and is currently their VP of
                      Administration. Frank enjoys biking, playing D&D, and
                      camping. They have two dogs, Doug and Lily, and two
                      chinchillas, Flipper and Dipper. They both enjoy hiking at
                      Texas State Parks and finding new Houston restaurants to
                      enjoy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("bencomo-popup")}
              >
                <img
                  src="/images/associates/bencomo.png"
                  height="200"
                  alt="Associate Bencomo"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("bencomo-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Mario Javier Bencomo
                </h4>
              </div>
              <p class="position">
                Postdoctoral Instructor, CAAM Department, Rice
              </p>
              <a href="mailto: mjb6@rice.edu" class="link">
                mjb6@rice.edu
              </a>
              <div id="bencomo-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseBencomo"
                    class="close-button"
                    onClick={() => closePopup("bencomo-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      Mario Javier Bencomo
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Currently a Postdoctoral Instructor in the Computational
                      and Applied Mathematics (CAAM) Department at Rice.
                      Received my Ph.D. in 2017 and M.A. in 2015 from Rice in
                      applied math. In 2011 I graduated from the University of
                      Texas at El Paso (UTEP) with a B.S. in physics and applied
                      math. My research interests include numerical PDEs for
                      wave phenomena and related inverse and optimal control
                      problems. I also have a secondary passion for mariachi
                      music. I am currently the music director for Rice's
                      mariachi band, Mariachi Luna Llena.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("brown-popup")}
              >
                <img
                  src="/images/associates/brown.png"
                  height="200"
                  alt="Associate Brown"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("brown-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Danika Brown
                </h4>
              </div>
              <p class="position">Director, Center for Civic Leadership</p>
              <a href="mailto: danika@rice.edu" class="link">
                danika@rice.edu
              </a>
              <div id="brown-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label=" CloseBrown"
                    class="close-button"
                    onClick={() => closePopup("brown-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Danika Brown</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Originally from Nevada, Danika did her undergraduate
                      degree in English and Philosophy at University of Nevada,
                      Reno, her MA in English at Arizona State, and her PhD in
                      Rhetoric and the Teaching of English at the University of
                      Arizona. She was an associate professor of English and
                      founding Director of the Office of Undergraduate Research
                      and Service Learning at the University of Texas, Pan
                      American (now RGV) before coming to Rice to serve as a
                      Director in the Center for Civic Leadership where she
                      oversees the Certificate in Civic Leadership programming
                      and fellowship advising. She is an avid dog lover,
                      puzzler, and bird watcher. She is especially happy to
                      support students seeking civic and community engaged
                      opportunities. As a first-generation low income college
                      student, she is also a resource for navigating the
                      resources and challenges for students with similar
                      backgrounds. She hopes to be a strong ally for all
                      students at Rice.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("chatfield-popup")}
              >
                <img
                  src="/images/associates/chatfield.png"
                  height="200"
                  alt="Associate Chatfield"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("chatfield-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Carlyn &amp; Mark Chatfield
                </h4>
              </div>
              <p class="position">
                Retired Staff (Enrollment, OIT, Computer Science Departments);
                Retired Shell IT Project Manager
              </p>
              <a href="mailto: carlyn@rice.edu" class="link">
                carlyn@rice.edu
              </a>
              <div id="chatfield-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseChatfield"
                    class="close-button"
                    onClick={() => closePopup("chatfield-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      Carlyn &amp; Mark Chatfield
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Carlyn and Mark became Wiess College Associates around
                      2004, when one of Carlyn's Rice OIT colleagues invited her
                      to apply. They host an O-Week group each year and
                      developed a customized board game - "The Game" - to help
                      new students learn their way around Rice. Like Sorry,
                      players attempt to cross the campus while evading
                      opponents. Like Clue, there are secret passages; ours are
                      called Art & Archi Detours. Carlyn also hosts cooking
                      classes and other study breaks at Wiess.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("corcuera-popup")}
              >
                <img
                  src="/images/associates/corcuera.png"
                  height="200"
                  alt="Associate Corcuera"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("corcuera-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Maria (Malé) Corcuera
                </h4>
              </div>
              <p class="position">
                International Advisor at OISS, Rice University
              </p>
              <a href="mailto: mec3@rice.edu" class="link">
                mec3@rice.edu
              </a>
              <div id="corcuera-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseCorcuera"
                    class="close-button"
                    onClick={() => closePopup("corcuera-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      Maria (Malé) Corcuera
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Maria Corcuera is an International Adviser for Exchange
                      Scholars at OISS. She oversees the immigration paperwork
                      process for Exchange Scholars with Jenny Brydon. Maria was
                      born and raised in Mexico City, and has a Law degree from
                      UNAM. She loves ballet, music, theater and gardening.
                      Maria moved to Houston in 1999 with her husband Manolo and
                      their two daughters, Ana and Sofia. She lives in Sugar
                      Land and is proud of being a Wiess Associate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("flatt-popup")}
              >
                <img
                  src="/images/associates/flatt.png"
                  height="200"
                  alt="Associate Flatt"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("flatt-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Robert &amp; Nancy Flatt
                </h4>
              </div>
              <p class="position">
                Adjunct Professor of Business Administration at the Jones
                Graduate School (34 years) &amp; Vagabond Nature Photographer
                for Brazos Tower at Bayou Manor; Retired
              </p>
              <a href="mailto: flattr@rice.edu" class="link">
                flattr@rice.edu
              </a>
              <div id="flatt-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseFlatt"
                    class="close-button"
                    onClick={() => closePopup("flatt-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      Robert &amp; Nancy Flatt
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Robert Flatt, and his wife Nancy met when they were in
                      high school and were undergraduates together at Rice
                      University. Robert was in Wiess and Nancy in Jones. Nancy
                      is a devoted Girl Scout Leader and outdoor trainer, Zoo
                      Docent and does extensive volunteer work at Rice and the
                      United Way. Robert was diagnosed with Parkinson’s (PD) in
                      1999. Robert and Nancy have travelled extensively to wild
                      and beautiful places– from Borneo to Madagascar and Brazil
                      to Antarctica and Alaska to Indonesia. PD gave Robert the
                      gift of time to take photography classes at Rice
                      University. He has self-published two photography books
                      “West Boulevard Night Herons” and “Rice's Owls”. A third
                      book “Healing Art – Don’t Let Anything Ruin Your Day” was
                      published by Bright Sky Press in 2016.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("galindo-popup")}
              >
                <img
                  src="/images/associates/galindo.png"
                  height="200"
                  alt="Associate Galindo"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("galindo-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Andrea Galindo
                </h4>
              </div>
              <p class="position">Post Award Administrator, ECE Department</p>
              <a href="mailto: andrea.galindo@rice.edu" class="link">
                andrea.galindo@rice.edu
              </a>
              <div id="galindo-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseGalindo"
                    class="close-button"
                    onClick={() => closePopup("galindo-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Andrea Galindo</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Administrator by day, mariachi by night! Well, and mom all
                      the time ;) I have a B.S and M.S. in biology, but my
                      passion is in the performing arts. I have been singing
                      with the Mariachi Luna Llena since it foundation, in 2013.
                      I have been a Wiess Associate for about 6 years, and won
                      Associate of the Year twice in a row. I like to engage
                      physically and intellectually, so I enjoy physical
                      activities, sports, crafting, and puzzles. I love
                      traveling, cooking, yoga, gardening and more.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("garvey-popup")}
              >
                <img
                  src="/images/associates/garvey.png"
                  height="200"
                  alt="Associate Garvey"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("garvey-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Morgan Garvey
                </h4>
              </div>
              <p class="position">
                Associate Director of Stewardship at Baker Institute for Public
                Policy
              </p>
              <a href="mailto: morgan.garvey@rice.edu" class="link">
                morgan.garvey@rice.edu
              </a>
              <div id="garvey-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseGarvey"
                    class="close-button"
                    onClick={() => closePopup("garvey-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Morgan Garvey</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      I am the Associate Director of Stewardship at Rice
                      University’s Baker Institute for Public Policy --the No. 1
                      ranked university-based think-tank in the world. I serve
                      on the boards of directors of the Hermann Park Conservancy
                      and the Jung Center of Houston and hold an MBA from
                      Arizona State University and a BBA in Finance from the
                      University of Texas at Austin.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("hudlow-popup")}
              >
                <img
                  src="/images/associates/hudlow.png"
                  height="200"
                  alt="Associate Hudlow"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("hudlow-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Doward &amp; Christie Hudlow
                </h4>
              </div>
              <p class="position">
                Director of Development Research and Analysis, Development and
                Alumni Relations; Terminal Operations and Supply Chain
                Compliance Coordinator, Ineos USA
              </p>
              <p>
                <a href="mailto: doward@rice.edu" class="link">
                  doward@rice.edu
                </a>
                ;{" "}
                <a href="mailto: ceghudlow@gmail.com" class="link">
                  ceghudlow@gmail.com
                </a>
              </p>
              <div id="hudlow-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseHudlow"
                    class="close-button"
                    onClick={() => closePopup("hudlow-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      Doward &amp; Christie Hudlow
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Doward and Christie Hudlow are both Wiessmen from the
                      class of 2001. Doward grew up in Houston near campus and
                      Christie came from the great state of South Dakota (yes,
                      people live there). As Wiessmen we enjoyed, well,
                      everything . . . Tabletop, Cabinet, Court, Powderpuff,
                      intramural sports, Acabowl, Backabowl . . . you aria-label
                      it. We were lucky enough to be RAs at Wiess from 2002 to
                      2008, which was an amazing experience. Now we live near
                      campus with our two sons, Riley (13) and Chase (11). We
                      enjoy having an O-Week group to our house every year.
                      Christie can be a great resource for young engineers,
                      Doward would be happy to meet anyone interested or even
                      just curious about what Development and Alumni Relations
                      are about (fundraising and alumni engagement). Doward’s an
                      avid cyclist who’s always happy to discuss riding or, even
                      better, go for ride. Our boys are fun gamer/sports
                      enthusiasts so hopefully you’ll get a chance to meet them
                      too!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("hutchinson-popup")}
              >
                <img
                  src="/images/associates/hutchinson.png"
                  height="200"
                  alt="Associate Hutchinson"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("hutchinson-popup")}
                  style={{ cursor: "pointer" }}
                >
                  John &amp; Paula Hutchinson
                </h4>
              </div>
              <p class="position">
                {" "}
                Professor of Chemistry; Adjunct Professor; Former Wiess
                Magisters
              </p>
              <p>
                <a href="mailto: pkhutch@gmail.com" class="link">
                  pkhutch@gmail.com
                </a>
                ;{" "}
                <a href="mailto: jshutch@rice.edu" class="link">
                  jshutch@rice.edu
                </a>
              </p>
              <div id="hutchinson-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="HutchinsonClose"
                    class="close-button"
                    onClick={() => closePopup("hutchinson-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      John &amp; Paula Hutchinson
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      As magisters of Wiess for 7 years, we know what a unique
                      and special environment the college provides, and how
                      important faculty associates are in being a resource to
                      the students. Everyone knows John as a chemistry professor
                      and former Dean of Undergraduates, and Paula is a former
                      journalist, attorney and teacher. We are avid cyclists and
                      hikers, and if we're not around Rice, we're up at our
                      cabin in Colorado. We look forward to having a great
                      relationship with our O-Week students and being a resource
                      to all!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("lee-popup")}
              >
                <img
                  src="/images/associates/lee.png"
                  height="200"
                  alt="Associate Lee"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("lee-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Eric Lee
                </h4>
              </div>
              <p class="position">
                Assistant Professor, Baylor College of Medicine
              </p>
              <a href="mailto: erictlee@alumni.rice.edu" class="link">
                erictlee@alumni.rice.edu
              </a>
              <div id="lee-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="LeeButton"
                    class="close-button"
                    onClick={() => closePopup("lee-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Eric Lee</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Eric (BA Biochemistry/Psychology, Jones ’00) from Port
                      Lavaca, TX, went to medical school at UTSW in Dallas,
                      followed by family medicine residency in Houston. He has
                      worked for the Baylor College of Medicine since. He is
                      involved in medical student education (director/adviser in
                      the learning communities program, facilitator for the MS1
                      doctoring course, admissions committee work, mentor for
                      students interested in family medicine, etc.). Christine
                      (BA Biology/Sociology, Wiess ’02) from Lake Jackson, TX,
                      went to medical school at UTHSCSA (Long) in San Antonio.
                      Her focus is on the education of Michael (12), Nathan
                      (10), and TedE (7), who are in a hybrid Christian
                      school/satellite day (“homeschool”) program. She also
                      teaches Biology and Anatomy/Physiology at their school.
                      She also provides impartial/objective expertise in
                      insurance/worker’s compensation cases.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("mack-popup")}
              >
                <img
                  src="/images/associates/mack.png"
                  height="200"
                  alt="Associate Mack"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("mack-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Brandon Mack
                </h4>
              </div>
              <p class="position">Associate Director of Admission</p>
              <a href="mailto: bmack@rice.edu" class="link">
                bmack@rice.edu
              </a>
              <div id="mack-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseMack"
                    class="close-button"
                    onClick={() => closePopup("mack-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Brandon Mack</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Brandon Mack is an Associate Director of Admission &
                      Coordinator of Transfer Admission at Rice University. Mack
                      is also a community activist and sociologist dedicated to
                      issues related to the intersections of race, gender, and
                      sexual orientation. He has conducted research on
                      effemiphobia, the negativity related to effeminate gay
                      men. His work has been featured in a GLAAD Media Award
                      winning article and he has presented his work at national
                      conferences such as the National LGBTQ Task Force:
                      Creating Change and NBJC's Out on the Hill. He is a lead
                      organizer with Black Lives Matter: Houston. Co-Chapter
                      Director of New Leaders Council - Houston. Screening
                      Committee and Education and Advocacy Chair of the Houston
                      GLBT Political Caucus.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("martinez-popup")}
              >
                <img
                  src="/images/associates/martinez.png"
                  height="200"
                  alt="Associate Martinez"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("martinez-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Maria Martinez
                </h4>
              </div>
              <p class="position">
                {" "}
                Program and Events Coordinator, Department of Visual and
                Dramatic Arts
              </p>
              <a href="mailto: maria.martinez@rice.edu" class="link">
                maria.martinez@rice.edu
              </a>
              <div id="martinez-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseMartinez"
                    class="close-button"
                    onClick={() => closePopup("martinez-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Maria Martinez</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Maria Martinez received her B.A. in Advertising and Public
                      Relations from City University New York, graduating cum
                      laude. After moving back to Houston, Texas in 2008, she
                      became an entrepreneur opening a retail business and later
                      creating an organic beauty line consisting of lip colors,
                      balms, and perfumes. She has worked for internationally
                      recognizable brands such as Converse in their Public
                      Relations department, and local Houston festivals Day for
                      Night as the Media Buyer, Free Press Summer Festival as
                      the Market Vendor Manager, and with ongoing Houston
                      Vintage as a co-organizer and producer. Maria enjoys
                      organizing and promoting events, engaging and bringing
                      communities together, and reviving the art of socializing.
                      In her spare time she works with friends on music related
                      projects and takes pleasure in books, films, theatre
                      productions, traveling, playing drums, DIY projects, and
                      all things vintage.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("myers-popup")}
              >
                <img
                  src="/images/associates/myers.png"
                  height="200"
                  alt="Associate Myers"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("myers-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Risa &amp; Jeff Myers
                </h4>
              </div>
              <p class="position">
                Assistant Teaching Professor, Rice University; General Manager,
                Raven Aerospace Technology
              </p>
              <a href="mailto: rbm2@rice.edu" class="link">
                rbm2@rice.edu
              </a>
              <div id="myers-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseMyers"
                    class="close-button"
                    onClick={() => closePopup("myers-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      Risa &amp; Jeff Myers
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Risa is an Assistant Teaching Professor in the Computer
                      Science department where she teaches students about
                      databases and data science. She also is a Data Scientist
                      with the Children's Environmental Health Initiative at the
                      University of Notre Dame. She completed a "late" life PhD
                      (at Rice) after a career in software engineering and
                      project management. Jeff is an aerospace engineer and
                      co-owner of a small consulting firm. We're happy to talk
                      with people about career paths and options.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("oswald-popup")}
              >
                <img
                  src="/images/associates/oswald.png"
                  height="200"
                  alt="Associate Oswald"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("oswald-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Fred Oswald
                </h4>
              </div>
              <p class="position">Professor</p>
              <a href="mailto: foswald@rice.edu" class="link">
                foswald@rice.edu
              </a>
              <div id="oswald-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseOswald"
                    class="close-button"
                    onClick={() => closePopup("oswald-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Fred Oswald</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Organizational psychologist who researches psychological
                      testing in employment, college admissions, and military
                      settings (e.g., measure development, psychometrics,
                      machine learning applications, legal implications).
                      Co-edited Workforce Readiness and the Future of Work
                      (Oswald, Behrend, & Foster, 2019). Current Chair of the
                      Board on Human-Systems Integration (BOHSI) of the National
                      Academies; incoming Chair fo the Board of Scientific
                      Affairs of the American Psychological Association; current
                      Associate Editor of the Journal of Applied Psychology;
                      former President of the Society of Industrial and
                      Organizational Psychology (SIOP, 2017-18). Occasional
                      touraria-labelnt Scrabble® player, inadequate-yet-engaged
                      pinball player, dedicated daily Inner Loop walker.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("pai-popup")}
              >
                <img
                  src="/images/associates/pai.png"
                  height="200"
                  alt="Associate Pai"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("pai-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Mallesh Pai
                </h4>
              </div>
              <p class="position">Department of Economics, Rice University</p>
              <a href="mailto: mallesh.pai@rice.edu" class="link">
                mallesh.pai@rice.edu
              </a>
              <div id="pai-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="ClosePai"
                    class="close-button"
                    onClick={() => closePopup("pai-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Mallesh Pai</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      I'm an Associate Prof and also the incoming Director of
                      Undergrad Studies in the econ department. My primary
                      research interests are in microeconomic theory
                      (specifically game theory/ mechanism design). In
                      particular, I'm interested in stuff at the intersection of
                      computer science and economic theory: both understanding
                      the modern web/ social media/ sharing economy via the
                      prism of game theory, and more recently, contributing to
                      the theory of machine learning (which has some overlap
                      with game theory)! Feel free to reach out if you'd like to
                      chat about economics@rice, my research, or more broadly
                      e.g. opportunities in econ, and in tech for econ-y types.
                      I'm generally down for coffee... Also glad to chat over
                      lunch in Wiess (whenever they let us back in!).. I look
                      like the pic except I currently have a beard and
                      (significantly) less hair than when this was taken.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("ramos-popup")}
              >
                <img
                  src="/images/associates/ramos.png"
                  height="200"
                  alt="Associate Ramos"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("ramos-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Renata Ramos &amp; Lenin Terrazas
                </h4>
              </div>
              <p class="position">
                Associate Teaching Professor of Bioengineering Associate Dean
                for Academic Affairs, School of Engineering & Consultant
              </p>
              <p>
                <a href="mailto: renata.ramos@rice.edu" class="link">
                  renata.ramos@rice.edu
                </a>
                ;{" "}
                <a href="mailto: lenin.terrazas@gmail.com" class="link">
                  lenin.terrazas@gmail.com
                </a>
              </p>
              <div id="ramos-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseRamos"
                    class="close-button"
                    onClick={() => closePopup("ramos-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      Renata Ramos &amp; Lenin Terrazas
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Renata is the Associate Dean for Academic Affairs for the
                      School of Engineering and an Associate Teaching Professor
                      in Bioengineering. She began her career at Rice in 2008.
                      Lenin has a background in engineering and change
                      management. Gavin has love for all things sports
                      -particularly baseball- and a passion for books (both
                      fostered by Wiessmen). Evan has a newly found love for
                      swimming and enjoys puzzles and painting. They both LOVE
                      going to the upper commons to trick Wiessmen into letting
                      them play videogames with them. Wiess was our first family
                      home. We were Wiess RAs from 2011 to 2018 and lived on the
                      3rd floor. Our closets are still mainly goldenrod! TFW!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("a-santos-popup")}
              >
                <img
                  src="/images/associates/a-santos.png"
                  height="200"
                  alt="Associate ASantos"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("a-santos-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Anne K. T. Santos
                </h4>
              </div>
              <p class="position">
                HR Administrator/EA to the Dean - Susanne M. Glasscock School of
                Continuing Studies
              </p>
              <a href="mailto: aksantos@rice.edu" class="link">
                aksantos@rice.edu
              </a>
              <div id="a-santos-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseASantos"
                    class="close-button"
                    onClick={() => closePopup("a-santos-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Anne K. T. Santos</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      FHi TFW! I love being a Wiess Associate and look forward
                      to many more years getting to know students! I work in the
                      Susanne M. Glasscock School of Continuing Studies
                      supporting the Dean and the School with administrative
                      functions. Fun fact: some Wiess alums have worked for us
                      over the summer summer months and beyond! I'm a
                      Jill-of-all-trades with a background in Hospitality,
                      International Commerce, and FinTech. I graduated from
                      Boston University (Go Terriers!) and found my happy niche
                      in higher education at Rice University (Go Owls!). During
                      my free time, I enjoy traveling for standup paddleboard
                      races, building LEGOs, floating (OneWheel) Hermann Park,
                      and teaching my pup (Louie) new tricks.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("h-santos-popup")}
              >
                <img
                  src="/images/associates/h-santos.png"
                  height="200"
                  alt="Associate HSantos"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("h-santos-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Hélade Scutti Santos
                </h4>
              </div>
              <p class="position">
                Director of Language Instruction and Portuguese Lecturer at the
                Center for Languages and Intercultural Communication
              </p>
              <a href="mailto: helade.santos@rice.edu" class="link">
                helade.santos@rice.edu
              </a>
              <div id="h-santos-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseHSantos"
                    class="close-button"
                    onClick={() => closePopup("h-santos-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      Hélade Scutti Santos
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      My aria-label is Hélade. I teach Portuguese at Rice and I
                      am also the Director of the Center for Languages and
                      Intercultural Communication. I am from Brazil and I came
                      to the US to do my PhD in Spanish and Portuguese
                      Linguistics. I absolutely love teaching at Rice, mainly
                      because of the students I have had and the relationships I
                      have developed with each and everyone of them. Teaching
                      languages and thinking about different opportunities for
                      Rice students to experience other languages and cultures,
                      including study abroad, make me truly excited about my
                      work. When I am not working I love to do simple things: to
                      be home with my family, watch good movies, go for long
                      walks, practice yoga and work on my garden.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("shimizu-popup")}
              >
                <img
                  src="/images/associates/shimizu.png"
                  height="200"
                  alt="Associate Shimizu"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("shimizu-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Sayuri Guthrie Shimizu
                </h4>
              </div>
              <p class="position"> Dunlevie Family Professor of History</p>
              <a href="mailto: sg45@rice.edu" class="link">
                sg45@rice.edu
              </a>
              <div id="shimizu-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseShimizu"
                    class="close-button"
                    onClick={() => closePopup("shimizu-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>
                      Sayuri Guthrie Shimizu
                    </h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      I am a dual citizen of the US and Japan but in truth I
                      consider myself a "citizen of the world," (having grown up
                      in five different countries)." I teach and research on the
                      history of US-East Asian relations, the Asia-Pacific World
                      and global history of sports. I am a former recipient of
                      Fulbright (2), MacArthur, Mellon Foundation, SSRC, and
                      Woodrow Wilson Center fellowships. I am a licensed (in
                      Japan) chef and love to cook for my family, friends and,
                      most importantly, my students at Rice. I welcome dinner
                      guests from Wiess!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("sneller-popup")}
              >
                <img
                  src="/images/associates/sneller.png"
                  height="200"
                  alt="Associate Sneller"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("sneller-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Chris Sneller
                </h4>
              </div>
              <p class="position">
                Director of Innovation; Bridges International
              </p>
              <a href="mailto: csneller@bridgesinternational.com" class="link">
                csneller@bridgesinternational.com
              </a>
              <div id="sneller-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseSneller"
                    class="close-button"
                    onClick={() => closePopup("sneller-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Chris Sneller</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      I've been an Associate at Wiess for 14 years but I had a
                      3-year pause while I did a PhD in London. My area of
                      research is historical theology (my dissertation focused
                      on Christianity in 20th century China). I have six kids,
                      ranging from 20 to 8 years old. In my free time I enjoy
                      doing CrossFit, watching Arsenal Football Club, and
                      mountain biking. At Rice I like hanging out at Wiess and
                      teaching an English class in the Office of International
                      Students and Scholars.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("strassmann-popup")}
              >
                <img
                  src="/images/associates/strassmann.png"
                  height="200"
                  alt="Associate Strassmann"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("strassmann-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Diana Strassmann
                </h4>
              </div>
              <p class="position">
                Carolyn and Fred McManis Distinguished Professor in the
                Practice, Center for Women, Gender, & Sexuality; Director,
                Program in Poverty, Justice, & Human Capabilities; Founding
                Editor, Feminist Economics
              </p>
              <a href="mailto: dls@rice.edu" class="link">
                dls@rice.edu
              </a>
              <div id="strassmann-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseStrassmann"
                    class="close-button"
                    onClick={() => closePopup("strassmann-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Diana Strassmann</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      I am the Director of Rice’s Program in Poverty, Justice,
                      and Human Capabilities and the founding editor of the
                      journal Feminist Economics. I am passionate about
                      inclusive justice and knowledge, and love talking with
                      students and listening to their ideas. Birding and hiking
                      give me joy.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("tauzin-popup")}
              >
                <img
                  src="/images/associates/tauzin.png"
                  height="200"
                  alt="Associate Tauzin"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("tauzin-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Joey Tauzin
                </h4>
              </div>
              <p class="position">Research Scientist/Lecturer, Chemistry</p>
              <a href="mailto: ljt1@rice.edu" class="link">
                ljt1@rice.edu
              </a>
              <div id="tauzin-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseTauzin"
                    class="close-button"
                    onClick={() => closePopup("tauzin-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Joey Tauzin</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      I am a research scientist working for Christy Landes and
                      Stephan Link in the chemistry department. I received my
                      B.S. in chemistry from LSU and went on to get a PhD in
                      physical chemistry from Rice. My work involves lots of
                      different kinds of spectroscopy and microscopy
                      particularly involving single molecules and plasmonic
                      nanoparticles.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("ustundag-popup")}
              >
                <img
                  src="/images/associates/ustundag.png"
                  height="200"
                  alt="Associate Ustundag"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("ustundag-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Efe Ustundag
                </h4>
              </div>
              <p class="position">Head Men’s Tennis Coach</p>
              <a href="mailto: efe@rice.edu" class="link">
                efe@rice.edu
              </a>
              <div id="ustundag-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseUstundag"
                    class="close-button"
                    onClick={() => closePopup("ustundag-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Efe Ustundag</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      Hi There Wiess- I am the Head Men’s Tennis Coach and a
                      Rice Alum (Class of ‘99). After graduating from Rice, I
                      returned to my home country Turkey and played on the
                      professional tennis tour for about 2.5 years. I was lucky
                      to return to Rice as an assistant coach in 2004 and was
                      even more fortunate to become the head coach in 2012. I
                      have been an associate at Wiess since 2005. I am married
                      to a rock star associate professor of neurology at UT
                      medical school and we have an 8 year old daughter. TFW!!!
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("wagner-popup")}
              >
                <img
                  src="/images/associates/wagner.png"
                  height="200"
                  alt="Associate Wagner"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("wagner-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Dan Wagner
                </h4>
              </div>
              <p class="position">Associate Professor in BioSciences</p>
              <a href="mailto: dswagner@rice.edu" class="link">
                dswagner@rice.edu
              </a>
              <div id="wagner-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseWagner"
                    class="close-button"
                    onClick={() => closePopup("wagner-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Dan Wagner</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      In my day job I'm a developmental biologist. I study the
                      genetic regulation of development in zebrafish and snails.
                      I'm a strong supporter of undergrad research and other
                      avenues of engagement with science. I've been the faculty
                      sponsor of Catalyst (http://ricecatalyst.org/) since it's
                      creation. If you are interested in any of these things
                      drop me a line.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="person-card">
              <div
                class="prof-pic-with-overlay"
                onClick={() => openPopup("xiao-popup")}
              >
                <img
                  src="/images/associates/xiao.png"
                  height="200"
                  alt="Associate Xiao"
                />
                <div class="overlay">
                  <div class="overlay-text">
                    <h4>Learn More</h4>
                  </div>
                </div>
              </div>
              <div class="div-block-15">
                <h4
                  class="h4"
                  onClick={() => openPopup("xiao-popup")}
                  style={{ cursor: "pointer" }}
                >
                  Han Xiao
                </h4>
              </div>
              <p class="position">
                Assistant Professor of Chemistry, Biosciences, and
                Bioengineering
              </p>
              <a href="mailto: han.xiao@rice.edu" class="link">
                han.xiao@rice.edu
              </a>
              <div id="xiao-popup" class="popup-base">
                <div class="popup-content">
                  <button
                    aria-label="CloseXiao"
                    class="close-button"
                    onClick={() => closePopup("xiao-popup")}
                  >
                    &times;
                  </button>
                  <div class="popup-text-holder">
                    <h4 style={{ textAlign: "center" }}>Han Xiao</h4>
                    <p style={{ fontSize: "16px", textAlign: "left" }}>
                      I am currently an Assistant Professor of Chemistry,
                      Biosciences, and Bioengineering at Rice University. I
                      obtained my undergraduate degree from the University of
                      Science and Technology of China and a PhD degree in
                      chemical biology at the Scripps Research Institute. My
                      research focuses on the development of various chemical
                      tools that allow us to precisely probe and manipulate
                      biological systems. My program has a strong translational
                      focus, seeking to initiate new clinical opportunities, and
                      contribute to advances in chemical biology, glycobiology,
                      and cancer immunology.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Associates;
